import React from 'react'
import {
  Show,
  TextField,
  SimpleShowLayout,
  DateField,
  NumberField,
  ArrayField,
  ReferenceField,
  Datagrid,
  FunctionField,
  withTranslate,
} from 'react-admin'
import _ from 'lodash'
import { priceFormatter } from '../../../../utils'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'

const show = ({ translate, ...props }) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='id' />
        <ReferenceField
          label={translate('resources.publisher-earnings.fields.publisher')}
          link='show'
          source='publisher.id'
          reference='users'
        >
          <TextField source='nickname' />
        </ReferenceField>
        <FunctionField
          source='total'
          render={(record, source) => {
            let price = _.get(record, source, '')
            price = price / 100
            return priceFormatter(price)
          }}
        />
        <NumberField source='purchases' />
        <DateField source='periodStart' />
        <DateField source='periodEnd' />
        <ArrayField source='items'>
          <Datagrid>
            <TextField source='type' />
            <ReferenceField
              label={translate('resources.publisher-earnings.fields.mgmUser')}
              sortable={false}
              link='show'
              source='mgmUser.id'
              reference='users'
            >
              <TextField source='nickname' />
            </ReferenceField>
            <FunctionField
              source='amount'
              render={(record, source) => {
                let price = _.get(record, source, '')
                price = price / 100
                return priceFormatter(price)
              }}
            />
            <ReferenceField
              label={translate('resources.publisher-earnings.fields.beneficiary')}
              sortable={false}
              link='show'
              source='beneficiary.id'
              reference='beneficiaries'
            >
              <TextField source='nameEs' />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
        <ArrayField source='contributions'>
          <Datagrid>
            <ReferenceField sortable={false} link='show' source='userId' reference='users'>
              <TextField source='nickname' />
            </ReferenceField>
            <ReferenceField sortable={false} link='show' source='beneficiaryId' reference='beneficiaries'>
              <TextField source='nameEs' />
            </ReferenceField>
            <NumberField sortable={false} source='percentage' />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  )
}

show.propTypes = {
  translate: PropTypes.func,
}

const enhanced = compose(withTranslate)

export default enhanced(show)
