import React from 'react'
import { Datagrid, TextField, List, withTranslate, ReferenceField } from 'react-admin'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'

const list = ({ translate, ...props }) => (
  <List {...props} exporter={false} bulkActionButtons={false}>
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='reason' />
      <TextField source='comment' />
      <ReferenceField
        label={translate('resources.comment-reports.fields.informer')}
        link='show'
        source='user.id'
        reference='users'
      >
        <TextField source='nickname' />
      </ReferenceField>
      <ReferenceField
        label={translate('resources.comment-reports.fields.commenter')}
        link='show'
        source='commenter.id'
        reference='users'
      >
        <TextField source='nickname' />
      </ReferenceField>
      <ReferenceField
        label={translate('resources.comment-reports.fields.video')}
        link='show'
        source='video.id'
        reference='videos'
      >
        <TextField source='title' />
      </ReferenceField>
    </Datagrid>
  </List>
)

list.propTypes = {
  translate: PropTypes.func,
}

const enhanced = compose(withTranslate)

export default enhanced(list)
