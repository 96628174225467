import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'

const RegularInput = ({ error, label, type, onChange }) => (
  <div style={styles.root}>
    <TextField
      style={styles.input}
      error={error}
      type={type}
      onChange={e => {
        const value = e.target.value
        onChange(value, e)
      }}
      label={label}
    />
  </div>
)

RegularInput.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  error: PropTypes.bool,
}

RegularInput.defaultProps = {
  onChange: () => {},
  type: 'text',
  error: false,
}

const enharce = withStyles(styles)

export default enharce(RegularInput)
