import React from 'react'
import {
  Show,
  TextField,
  SimpleShowLayout,
  FunctionField,
  ReferenceField,
  ImageField,
  DateField,
  BooleanField,
  withTranslate,
  NumberField,
} from 'react-admin'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ReactPlayer from 'react-player'

const VideoField = ({ source, record = {} }) => {
  const videoUrl = _.get(record, source)
  if (videoUrl) {
    return <ReactPlayer url={videoUrl} controls />
  } else {
    return '-----'
  }
}

VideoField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

VideoField.defaultProps = { addLabel: true }

const show = ({ translate, ...props }) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='id' />
        <FunctionField
          label={translate('resources.videos.fields.status')}
          render={record => translate(`resources.videos.fields.${record.status}`)}
        />
        <TextField source='title' />
        <TextField source='originalName' />
        <BooleanField source='highlighted' />
        <NumberField source='purchasesCount' />
        <ImageField source='thumbnail' />
        <ReferenceField
          link='show'
          label={translate('resources.videos.fields.owner')}
          source='owner.id'
          reference='users'
        >
          <TextField source='nickname' />
        </ReferenceField>
        <ReferenceField
          link='show'
          label={translate('resources.videos.fields.category')}
          source='category.id'
          reference='categories'
        >
          <TextField source='code' />
        </ReferenceField>
        <VideoField source='trailer' />
        <VideoField label={translate('resources.videos.fields.video')} source='url' />
        <DateField source='createdAt' />
        <DateField source='updatedAt' />
      </SimpleShowLayout>
    </Show>
  )
}

show.propTypes = {
  translate: PropTypes.func,
}

export default withTranslate(show)
