import React from 'react'
import { BooleanInput, Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, withTranslate } from 'react-admin'
import PropTypes from 'prop-types'

const edit = ({ translate, ...props }) => {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect='show'>
        <TextInput source='title' />
        <ReferenceInput
          perPage={100}
          label={translate('resources.videos.fields.category')}
          source='category.id'
          reference='categories'
        >
          <SelectInput optionText='code' />
        </ReferenceInput>
        <BooleanInput source='highlighted' />
      </SimpleForm>
    </Edit>
  )
}

edit.propTypes = {
  translate: PropTypes.func,
}

export default withTranslate(edit)
