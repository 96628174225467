export default {
  tabWrapper: {
    padding: '30px 15px',
  },
  spinnerWrapper: {
    textAlign: 'center',
  },
  pagination: {
    justifyContent: 'center',
    display: 'flex',
    marginTop: '30px',
  },
}
