export default {
  main: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: 'white',
    backgroundSize: 'cover',
    overflow: 'hidden',
    backgroundColor: '#dddddd',
  },
}
