import * as api from '../config/api'
import qs from 'qs'
import axios from 'axios'

export const login = async ({ username, password }) => {
  const request = new Request(`${api.BASE_URL}/api/login_check`, {
    method: 'POST',
    body: JSON.stringify({ username, password }),
    headers: new Headers({ 'Content-Type': 'application/json' }),
  })
  const response = await fetch(request)
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText)
  }

  return response.json()
}

export const deleteComment = async id => {
  const token = localStorage.getItem('token')
  const url = `${api.BASE_URL}/api/comment-reports/${id}/reaction`
  return axios.delete(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const deleteVideo = async id => {
  const token = localStorage.getItem('token')
  const url = `${api.BASE_URL}/api/videos/${id}`
  return axios.delete(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getTransationsByUser = async ({ userId, limit, page, offset, sort }) => {
  const token = localStorage.getItem('token')

  const params = {
    limit: limit || 5,
    page: page || 1,
    offset: offset || 0,
    sort: sort || ['createdAt,DESC'],
    filter: `user.id||eq||${userId}`,
  }

  const url = `${api.BASE_URL}/api/transactions?join=beneficiary&${qs.stringify(params, {
    skipNulls: true,
  })}`
  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getBeneficiaryEarnings = async beneficiaryId => {
  const token = localStorage.getItem('token')

  const url = `${api.BASE_URL}/api/beneficiary-earnings/${beneficiaryId}`
  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const updateUserContributions = async ({ userId, data }) => {
  const token = localStorage.getItem('token')
  const url = `${api.BASE_URL}/api/users/${userId}/contributions`
  return axios.put(url, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}
