import { roles } from './config/api'
import _ from 'lodash'

export const isAdminUser = permissions => {
  const isRoleAdmin = _.find(permissions, permission => permission === roles.ROLE_ADMIN)
  return !_.isNil(isRoleAdmin)
}

export function validatePassword(password) {
  var re = /^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$/
  return re.test(password)
}

export const priceFormatter = price => {
  const formatter = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
  })

  return formatter.format(price)
}
