import React, { Fragment, Component } from 'react'
import {
  Show,
  TextField,
  ReferenceField,
  showNotification,
  Notification,
  withTranslate,
  SimpleShowLayout,
  DateField,
  FunctionField,
  Confirm,
} from 'react-admin'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import { Button, Typography } from '@material-ui/core'
import * as API from '../../../../api'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'

class ShowView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isFetching: false,
    }
  }

  deleteVideo = async videoId => {
    const { translate, showNotification, history } = this.props
    this.setState({ isFetching: true })
    try {
      await API.deleteVideo(videoId)
      showNotification(translate('resources.video-reports.fields.successDelete'))
      history.push('video-reports')
    } catch {
      showNotification(translate('generalError'), 'warning')
    } finally {
      this.setState({ isFetching: false })
    }
  }

  handleDialogToogle = () => {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  render() {
    const { translate, ...props } = this.props
    const { isOpen, isFetching } = this.state

    return (
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source='id' />
          <TextField source='reason' />
          <DateField source='createdAt' />
          <DateField source='updatedAt' />
          <ReferenceField
            label={translate('resources.video-reports.fields.informer')}
            link='show'
            source='user.id'
            reference='users'
          >
            <TextField source='nickname' />
          </ReferenceField>
          <ReferenceField
            label={translate('resources.video-reports.fields.video')}
            link='show'
            source='video.id'
            reference='videos'
          >
            <TextField source='title' />
          </ReferenceField>
          <FunctionField
            source='video'
            label=''
            render={record => {
              const videoId = _.get(record, 'video.id', '')
              const videoStatus = _.get(record, 'video.status', '')

              if (videoStatus === 'deleted') {
                return (
                  <Typography variant='overline' component='p'>
                    <strong>{translate('resources.video-reports.fields.videoDeleted')}</strong>
                  </Typography>
                )
              } else {
                return (
                  <Fragment>
                    <Button variant='contained' color='primary' onClick={() => this.handleDialogToogle()}>
                      {translate('resources.video-reports.fields.deleteVideo')}
                    </Button>
                    <Confirm
                      isOpen={isOpen}
                      loading={isFetching}
                      title={translate('resources.video-reports.fields.deleteVideo')}
                      content={translate('resources.video-reports.fields.confirmDeleteVideo')}
                      onConfirm={() => this.deleteVideo(videoId)}
                      onClose={this.handleDialogToogle}
                    />
                  </Fragment>
                )
              }
            }}
          />

          <Notification autoHideDuration={5000} />
        </SimpleShowLayout>
      </Show>
    )
  }
}

ShowView.propTypes = {
  translate: PropTypes.func,
  showNotification: PropTypes.func,
  history: PropTypes.object,
}

const enhanced = compose(
  connect(null, {
    showNotification,
  }),
  withTranslate,
  withRouter
)

export default enhanced(ShowView)
