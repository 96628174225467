import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request'
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin'
import { BASE_URL, API_ENTRYPOINT } from '../config/api'
import _ from 'lodash'
import q from 'query-string'

const apiUrl = BASE_URL + API_ENTRYPOINT

const httpClient = (url, options = {}) => {
  const token = localStorage.getItem('token')
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }

  options.headers.set('Authorization', `Bearer ${token}`)
  return fetchUtils.fetchJson(url, options)
}

export default () => {
  const composeFilter = (paramsFilter, resource) => {
    if (paramsFilter === '' || (typeof paramsFilter.q !== 'undefined' && paramsFilter.q === '')) {
      paramsFilter = {}
    }

    const flatFilter = fetchUtils.flattenObject(paramsFilter)

    let filter = Object.keys(flatFilter).map(key => {
      const splitKey = key.split('||')
      let operator = splitKey[1] ? splitKey[1] : 'cont'
      let field = splitKey[0]
      let value = flatFilter[key]

      if (field.indexOf('_') === 0 && field.indexOf('.') > -1) {
        field = field.split(/\.(.+)/)[1]
      }

      //CUSTOM CODE
      if (resource === 'videos') {
        if (field === 'category') {
          field = 'category.id'
        } else if (field === 'owner') {
          field = 'owner.nickname'
        }
      }

      return { field, operator, value }
    })

    //CUSTOM, REMOVE EMPTY VALUES
    _.remove(filter, function (n) {
      return _.isObject(n.value) && _.isEmpty(n.value)
    })

    return filter
  }

  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = ''
    const options = {}
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination

        const query = RequestQueryBuilder.create({
          filter: composeFilter(params.filter, resource),
        })
          .setLimit(perPage)
          .setPage(page)
          .sortBy(params.sort)
          .setOffset((page - 1) * perPage)
          .query()

        //CUSTOM CODE
        if (resource === 'videos') {
          url = `${apiUrl}/${resource}?${query}&join=owner&join=category`
        } else if (resource === 'publisher-earnings') {
          url = `${apiUrl}/${resource}?${query}&join=publisher`
        } else if (resource === 'beneficiary-earnings') {
          const periodStart = _.get(params.filter, 'periodStart')
          const periodEnd = _.get(params.filter, 'periodEnd')

          const customQuery = {
            limit: perPage,
            page,
            periodStart,
            periodEnd,
          }

          const customQueryStringify = q.stringify(customQuery)
          url = `${apiUrl}/${resource}?${customQueryStringify}`
        } else {
          url = `${apiUrl}/${resource}?${query}`
        }

        break
      }
      case GET_ONE: {
        //CUSTOM CODE
        if (resource === 'videos') {
          url = `${apiUrl}/${resource}/${params.id}?join=owner&join=category`
        } else if (resource === 'users') {
          url = `${apiUrl}/${resource}/${params.id}?join=referredBy&join=contributions&join=contributions.beneficiary`
        } else if (resource === 'publisher-earnings') {
          url = `${apiUrl}/${resource}/${params.id}?join=publisher&join=items&join=items.mgmUser&join=items.beneficiary`
        } else {
          url = `${apiUrl}/${resource}/${params.id}`
        }
        break
      }
      case GET_MANY: {
        const query = RequestQueryBuilder.create()
          .setFilter({
            field: 'id',
            operator: CondOperator.IN,
            value: `${params.ids}`,
          })
          .query()

        url = `${apiUrl}/${resource}?${query}`

        break
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination
        const filter = composeFilter(params.filter)

        filter.push({
          field: params.target,
          operator: CondOperator.EQUALS,
          value: params.id,
        })

        const query = RequestQueryBuilder.create({
          filter,
        })
          .sortBy(params.sort)
          .setLimit(perPage)
          .setOffset((page - 1) * perPage)
          .query()

        url = `${apiUrl}/${resource}?${query}`

        break
      }
      case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`
        options.method = 'PATCH'

        //CUSTOM CODE
        if (resource === 'videos') {
          const id = _.get(params.data, 'id', '')
          const title = _.get(params.data, 'title', '')
          const category = _.get(params.data, 'category', '')
          const highlighted = _.get(params.data, 'highlighted', false)
          const formatParams = {}
          _.assign(formatParams, { id, title, category, highlighted })
          params.data = formatParams
        }

        options.body = JSON.stringify(params.data)
        break
      }
      case CREATE: {
        url = `${apiUrl}/${resource}`
        options.method = 'POST'
        options.body = JSON.stringify(params.data)
        break
      }
      case DELETE: {
        url = `${apiUrl}/${resource}/${params.id}`
        options.method = 'DELETE'
        break
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`)
    }
    return { url, options }
  }

  const convertHTTPResponse = (response, type, resource, params) => {
    const { json } = response

    switch (type) {
      case GET_LIST:
      case GET_MANY:
      case GET_MANY_REFERENCE:
        return {
          data: json.data,
          total: json.total,
        }
      case CREATE:
        return { data: { ...params.data, id: json.id } }
      case DELETE:
        return { data: { id: params.id } }
      default:
        return { data: json }
    }
  }

  return (type, resource, params) => {
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json),
      }))
    }
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json),
      }))
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params)
    return httpClient(url, options).then(response => convertHTTPResponse(response, type, resource, params))
  }
}
