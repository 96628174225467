import React from 'react'
import { Create, SimpleForm, TextInput, required } from 'react-admin'

const create = props => {
  return (
    <Create {...props}>
      <SimpleForm redirect='list'>
        <TextInput source='nameEs' validate={required()} />
        <TextInput source='nameEn' validate={required()} />
      </SimpleForm>
    </Create>
  )
}

export default create
