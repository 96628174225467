import React from 'react'
import { Show, TextField, SimpleShowLayout, DateField, FunctionField } from 'react-admin'
import _ from 'lodash'
import { priceFormatter } from '../../../../utils'

const show = props => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='id' />
        <FunctionField
          source='price'
          render={(record, source) => {
            let price = _.get(record, source, '')
            price = price / 100
            return priceFormatter(price)
          }}
        />
        <TextField source='videosAmount' />
        <TextField source='freeVideosAmount' />
        <DateField source='createdAt' />
        <DateField source='updatedAt' />
      </SimpleShowLayout>
    </Show>
  )
}

export default show
