import React from 'react'
import { Datagrid, TextField, List, FunctionField, ReferenceField, Filter, DateInput, withTranslate } from 'react-admin'
import _ from 'lodash'
import { priceFormatter } from '../../../../utils'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'

const CustomFilters = props => (
  <Filter {...props}>
    <DateInput source='periodStart' autoComplete='off' />
    <DateInput source='periodEnd' autoComplete='off' />
  </Filter>
)

const list = ({ translate, ...props }) => {
  return (
    <List {...props} filters={<CustomFilters />} exporter={false} bulkActionButtons={false}>
      <Datagrid rowClick='show'>
        <TextField source='id' sortable={false} />
        <ReferenceField
          sortable={false}
          label={translate('resources.beneficiary-earnings.fields.beneficiarie')}
          link='show'
          source='id'
          reference='beneficiaries'
        >
          <TextField source='nameEs' />
        </ReferenceField>
        <FunctionField
          sortable={false}
          source='total'
          render={(record, source) => {
            let price = _.get(record, source, '')
            price = price / 100
            return priceFormatter(price)
          }}
        />
      </Datagrid>
    </List>
  )
}

list.propTypes = {
  translate: PropTypes.func,
}

const enhanced = compose(withTranslate)

export default enhanced(list)
