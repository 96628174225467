import React from 'react'
import { Admin, Resource } from 'react-admin'
import admin from '../../../config/admin'
import {
  Users,
  Videos,
  Categories,
  Products,
  VideoReports,
  CommentReports,
  Beneficiaries,
  PublisherEarnings,
  BeneficiaryEarnings,
} from '../../pages'

import Amplify from 'aws-amplify'
import { AWS_CONFIG } from '../../../config/aws'

Amplify.configure(AWS_CONFIG)

const App = () => (
  <Admin {...admin}>
    <Resource name='users' {...Users} />
    <Resource name='videos' {...Videos} />
    <Resource name='categories' {...Categories} />
    <Resource name='products' {...Products} />
    <Resource name='video-reports' {...VideoReports} />
    <Resource name='comment-reports' {...CommentReports} />
    <Resource name='beneficiaries' {...Beneficiaries} />
    <Resource name='publisher-earnings' {...PublisherEarnings} />
    <Resource name='beneficiary-earnings' {...BeneficiaryEarnings} />
  </Admin>
)

export default App
