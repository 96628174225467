import React from 'react'
import { useCallback } from 'react'
import {
  Edit,
  SimpleForm,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  SaveButton,
  useUpdate,
  useRedirect,
  useNotify,
  Toolbar,
  DeleteButton,
} from 'react-admin'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as API from '../../../../api'
import styles from './styles'

const SaveWithNoteButton = props => {
  const [approve] = useUpdate('users', props.record.id, { isApproved: true }, props.record)
  const redirectTo = useRedirect()
  const notify = useNotify()
  const { basePath } = props
  const handleSaveData = useCallback(
    (values, redirect) => {
      const newValues = _.omit(values, ['contributions'])
      approve(
        { payload: { data: { ...newValues, average_note: 10 } } },
        {
          onSuccess: async ({ data: newRecord }) => {
            notify('ra.notification.updated', 'info', { smart_count: 1 })

            try {
              const beneficiariesData = _.get(values, 'contributions', [])
              const data = { data: beneficiariesData }
              const userId = values.id
              await API.updateUserContributions({ userId, data })
            } catch {
              notify('resources.users.fields.contributionsError', 'warning', { smart_count: 1 })
            } finally {
              redirectTo(redirect, basePath, newRecord.id, newRecord)
            }
          },
        }
      )
    },
    [approve, notify, redirectTo, basePath]
  )

  return <SaveButton {...props} onSave={handleSaveData} />
}

SaveWithNoteButton.propTypes = {
  record: PropTypes.object,
  basePath: PropTypes.string,
}

const PostCreateToolbar = props => (
  <Toolbar {...props} style={styles.toolbar}>
    <SaveWithNoteButton {...props} />
    <DeleteButton {...props} undoable={false} />
  </Toolbar>
)

const edit = props => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<PostCreateToolbar {...props} />} redirect='show'>
        <TextInput source='name' validate={required()} />
        <TextInput source='nickname' validate={required()} />
        <BooleanInput source='publisher' />
        <BooleanInput source='enabled' />
        <BooleanInput source='banned' />
        <ArrayInput source='contributions'>
          <SimpleFormIterator>
            <ReferenceInput source='beneficiaryId' reference='beneficiaries'>
              <SelectInput optionText='nameEs' />
            </ReferenceInput>
            <NumberInput source='percentage' />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export default edit
