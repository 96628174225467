import React from 'react'
import {
  Datagrid,
  TextField,
  List,
  NumberField,
  DateField,
  FunctionField,
  ReferenceField,
  withTranslate,
} from 'react-admin'
import _ from 'lodash'
import { priceFormatter } from '../../../../utils'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'

const list = ({ translate, ...props }) => (
  <List {...props} exporter={false} bulkActionButtons={false}>
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <ReferenceField
        label={translate('resources.publisher-earnings.fields.publisher')}
        link='show'
        source='publisher.id'
        reference='users'
      >
        <TextField source='nickname' />
      </ReferenceField>
      <FunctionField
        source='total'
        render={(record, source) => {
          let price = _.get(record, source, '')
          price = price / 100
          return priceFormatter(price)
        }}
      />
      <NumberField source='purchases' />
      <DateField source='periodStart' />
      <DateField source='periodEnd' />
    </Datagrid>
  </List>
)

list.propTypes = {
  translate: PropTypes.func,
}

const enhanced = compose(withTranslate)

export default enhanced(list)
