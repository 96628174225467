import _ from 'lodash'
import { Auth } from 'aws-amplify'
import { isAdminUser } from '../utils'
import jwtDecode from 'jwt-decode'

const login = {
  login: async ({ username, password }) => {
    try {
      const user = await Auth.signIn({ username, password })

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        window.location = '/?#/completePassword'
        return Promise.reject('Error')
      } else {
        const token = _.get(user, 'signInUserSession.accessToken.jwtToken', '')
        const decoded = jwtDecode(token)
        const permissions = _.get(decoded, 'cognito:groups')
        const isRoleAdmin = isAdminUser(permissions)

        if (!isRoleAdmin) {
          return Promise.reject()
        } else {
          localStorage.setItem('token', token)
          return Promise.resolve()
        }
      }
    } catch {
      return Promise.reject()
    }
  },
  logout: () => {
    localStorage.removeItem('token')
    return Promise.resolve()
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('token')
      return Promise.reject()
    }
    return Promise.resolve()
  },
  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject()
  },
  getPermissions: () => {
    try {
      const token = localStorage.getItem('token')
      const decoded = jwtDecode(token)
      const permissions = _.get(decoded, 'cognito:groups')
      return Promise.resolve(permissions)
    } catch {
      return Promise.reject()
    }
  },
}

export default login
