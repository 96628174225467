import React, { Component } from 'react'
import {
  Show,
  TextField,
  Tab,
  TabbedShowLayout,
  withTranslate,
  NumberField,
  DateField,
  BooleanField,
  ReferenceField,
  ArrayField,
  Datagrid,
} from 'react-admin'
import _ from 'lodash'
import * as API from '../../../../api'
import {
  Typography,
  CircularProgress,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableRow,
  TableHead,
} from '@material-ui/core'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import moment from 'moment'
import Pagination from '@material-ui/lab/Pagination'
import styles from './styles'

class ShowView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      limit: 5,
      page: 1,
      total: 0,
      isFetchingTransaction: false,
    }
  }

  async componentDidMount() {
    let { data, total, page, limit } = this.state
    this.getUserTransactionData(data, total, page, limit)
  }

  handleChangePage = async (event, page) => {
    let { data, total, limit } = this.state
    this.getUserTransactionData(data, total, page, limit)
  }

  getUserTransactionData = async (data, total, page, limit) => {
    try {
      const offset = limit * (page - 1)
      this.setState({ isFetchingTransaction: true })
      const userId = _.get(this.props, 'match.params.id', '')
      const response = await API.getTransationsByUser({ userId, limit, page, offset })
      data = _.get(response, 'data.data', '')
      total = _.get(response, 'data.total', '')
      page = _.get(response, 'data.page', '')
      this.setState({ data, total, page, isFetchingTransaction: false })
    } catch {
      this.setState({ isFetchingTransaction: false })
    }
  }

  render() {
    const { data, isFetchingTransaction, total, page, limit } = this.state
    const { translate, ...props } = this.props
    const totalPages = Math.ceil(total / limit)

    const formatTransaction = _.map(data, (transactionItem, key) => {
      const moneyAmount = _.get(transactionItem, 'moneyAmount', 0)
      const videosAmount = _.get(transactionItem, 'videosAmount', 0)
      const donationAmount = _.get(transactionItem, 'donationAmount', 0)
      const donationPercentage = _.get(transactionItem, 'donationPercentage', 0)
      const status = _.get(transactionItem, 'status', '')
      const createdAt = transactionItem.createdAt ? moment(transactionItem.createdAt).format('DD/MM/YYYY HH:mm') : ''
      const updatedAt = transactionItem.updatedAt ? moment(transactionItem.updatedAt).format('DD/MM/YYYY HH:mm') : ''
      const beneficiary = _.get(transactionItem, 'beneficiary.nameEs', '')

      return (
        <TableRow key={key}>
          <TableCell>{moneyAmount / 100}€</TableCell>
          <TableCell>{videosAmount}</TableCell>
          <TableCell>{status}</TableCell>
          <TableCell>{beneficiary}</TableCell>
          <TableCell>{donationAmount / 100}€</TableCell>
          <TableCell>{donationPercentage}%</TableCell>
          <TableCell>{createdAt}</TableCell>
          <TableCell>{updatedAt}</TableCell>
        </TableRow>
      )
    })

    return (
      <Show {...props}>
        <TabbedShowLayout>
          <Tab label={translate('resources.users.fields.data')}>
            <TextField source='id' />
            <TextField source='name' />
            <TextField source='nickname' />
            <TextField source='email' />
            <NumberField source='availableVideos' />
            <BooleanField source='publisher' />
            <BooleanField source='enabled' />
            <BooleanField source='banned' />
            <DateField source='createdAt' />
            <DateField source='updatedAt' />
            <TextField source='referralCode' />
            <ReferenceField
              label={translate('resources.users.fields.referredBy')}
              link='show'
              source='referredBy.id'
              reference='users'
            >
              <TextField source='nickname' />
            </ReferenceField>
          </Tab>
          <Tab label={translate('resources.users.fields.transactionTab')}>
            <div style={styles.tabWrapper}>
              {isFetchingTransaction ? (
                <div style={styles.spinnerWrapper}>
                  <CircularProgress />
                </div>
              ) : data.length > 0 ? (
                <>
                  <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <strong>{translate('resources.users.fields.moneyAmount')}</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{translate('resources.users.fields.videosAmount')}</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{translate('resources.users.fields.status')}</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{translate('resources.users.fields.beneficiary')}</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{translate('resources.users.fields.donationAmount')}</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{translate('resources.users.fields.donationPercentage')}</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{translate('resources.users.fields.createdAt')}</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{translate('resources.users.fields.updatedAt')}</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>{formatTransaction}</TableBody>
                    </Table>
                  </TableContainer>

                  <Pagination
                    style={styles.pagination}
                    onChange={this.handleChangePage}
                    page={page}
                    count={totalPages}
                    color='primary'
                  />
                </>
              ) : (
                <Typography variant='body2' component='p'>
                  {translate('resources.users.fields.noResults')}
                </Typography>
              )}
            </div>
          </Tab>
          <Tab label={translate('resources.users.fields.contributions')}>
            <ArrayField source='contributions'>
              <Datagrid>
                <ReferenceField link='show' source='beneficiaryId' reference='beneficiaries'>
                  <TextField source='nameEs' />
                </ReferenceField>
                <NumberField source='percentage' />
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    )
  }
}

ShowView.propTypes = {
  translate: PropTypes.func,
}

const enhanced = compose(withTranslate)

export default enhanced(ShowView)
