import React from 'react'
import {
  List,
  Datagrid,
  ReferenceInput,
  BooleanInput,
  SelectInput,
  Filter,
  TextInput,
  EditButton,
  TextField,
  ImageField,
  withTranslate,
  NumberField,
} from 'react-admin'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import { BulkActionsButtons } from '../../../molecules'

const Filters = ({ translate, ...props }) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        label={translate('resources.videos.fields.category')}
        source='category||eq'
        reference='categories'
      >
        <SelectInput optionText='code' emptyText={translate('resources.videos.fields.all')} />
      </ReferenceInput>
      <TextInput
        autoComplete='off'
        label={translate('resources.videos.fields.alias')}
        source='owner'
        reference='users'
      />
      <BooleanInput
        label={translate('resources.videos.fields.highlighted')}
        source='highlighted||eq'
        reference='highlighted'
      />
    </Filter>
  )
}

Filters.propTypes = {
  translate: PropTypes.func,
}

const CustomStatus = ({ translate, source, record = {} }) => (
  <span>{translate(`resources.videos.fields.${record[source]}`)}</span>
)

CustomStatus.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  translate: PropTypes.func,
}

const list = ({ translate, ...props }) => (
  <List
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<Filters translate={translate} />}
    exporter={false}
    bulkActionButtons={<BulkActionsButtons />}
  >
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='title' />
      <NumberField source='purchasesCount' />
      <ImageField source='thumbnail' />
      <TextField source='owner.nickname' />
      <TextField source='category.code' />
      <CustomStatus translate={translate} source='status' />
      <EditButton />
    </Datagrid>
  </List>
)

list.propTypes = {
  translate: PropTypes.func,
}

const enharced = compose(withTranslate)

export default enharced(list)
