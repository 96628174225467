import React from 'react'
import { Edit, SimpleForm, TextInput, required } from 'react-admin'

const edit = props => {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect='show'>
        <TextInput source='nameEs' validate={required()} />
        <TextInput source='nameEn' validate={required()} />
      </SimpleForm>
    </Edit>
  )
}

export default edit
