export default {
  delete: 'Eliminar',
  confirmDeleteContent: 'Se van a borrar los elementos seleccionados. ¿Confirmar acción?',
  completePassword: {
    title: 'Completar acceso',
    user: 'Usuario',
    tempPass: 'Contraseña Temporal',
    newPass: 'Nueva contraseña',
    send: 'Enviar',
    back: 'Volver',
    validatePassword:
      'La contraseña debe tener entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter.',
  },
  generalError: 'Ha ocurrido un error',
  passwordChanged: 'Contraseña actualizada',
  resources: {
    users: {
      name: 'Usuarios',
      fields: {
        name: 'Nombre',
        username: 'Nombre de usuario',
        email: 'Email',
        updatedAt: 'Actualizado el',
        originalName: 'Nombre original',
        publisher: 'Creador de contenido',
        enabled: 'Activo',
        nickname: 'Alias',
        banned: 'Baneado',
        availableVideos: 'Vídeos disponibles',
        moneyAmount: 'Cantidad de dinero',
        videosAmount: 'Cantidad de vídeos',
        status: 'Estado',
        createdAt: 'Creado el',
        transactionTab: 'Histórico de pagos',
        data: 'Datos',
        noResults: 'No hay resultados',
        referredBy: 'Referenciado por',
        referralCode: 'Código de referencia',
        beneficiaryId: 'Beneficiario',
        percentage: 'Porcentaje',
        contributions: 'Contribuciones',
        contributionsError: 'No se ha podido actualizar los datos de las contribuciones',
        beneficiary: 'Beneficiario',
        donationAmount: 'Donación',
        donationPercentage: 'Porcentaje de donación',
      },
    },
    videos: {
      name: 'Vídeos',
      fields: {
        title: 'Título',
        thumbnail: 'Miniatura',
        url: 'Url',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
        originalName: 'Nombre original',
        video: 'Vídeo',
        category: 'Categoría',
        all: 'Todas',
        user: 'Usuario',
        alias: 'Alias',
        owner: 'Propietario',
        'owner.nickname': 'Propietario',
        'category.code': 'Categoría',
        status: 'Estado',
        public: 'Público',
        private: 'Privado',
        deleted: 'Borrado',
        trailer: 'Trailer',
        programmed: 'Programado',
        purchasesCount: 'Compras',
        highlighted: 'Destacado',
      },
    },
    categories: {
      name: 'Categorías',
      fields: {
        code: 'Código',
        nameEs: 'Nombre en español',
        nameEn: 'Nombre en inglés',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
      },
    },
    products: {
      name: 'Productos',
      fields: {
        price: 'Precio',
        videosAmount: 'Cantidad de vídeos',
        freeVideosAmount: 'Cantidad de vídeos gratis',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
      },
      errors: {
        minZero: 'No puede contener valores negativos',
      },
    },
    'video-reports': {
      name: 'Denuncias(vídeos)',
      fields: {
        reason: 'Razón',
        comment: 'Comentario',
        solved: 'Resuelto',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
        informer: 'Denunciante',
        video: 'Vídeo',
        deleteVideo: 'Borrar vídeo',
        successDelete: 'Vídeo borrado',
        confirmDeleteVideo: '¿Está seguro que desea borrar el vídeo?',
        videoDeleted: 'Vídeo borrado',
      },
    },
    'comment-reports': {
      name: 'Denuncias(comentarios)',
      fields: {
        reason: 'Razón',
        comment: 'Comentario',
        solved: 'Resuelto',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
        informer: 'Denunciante',
        commenter: 'Usuario del comentario',
        video: 'Vídeo',
        successDelete: 'Comentario borrado',
        deleteComment: 'Borrar comentario',
        confirmDeleteComment: '¿Está seguro que desea borrar el comentario?',
        commentDeleted: 'Comentario borrado',
      },
    },
    beneficiaries: {
      name: 'Beneficiarios',
      fields: {
        nameEs: 'Nombre español',
        nameEn: 'Nombre inglés',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
      },
    },
    'publisher-earnings': {
      name: 'Ganancias(creador)',
      fields: {
        total: 'Total',
        purchases: 'Compras',
        periodStart: 'Comienzo',
        periodEnd: 'Fin',
        beneficiaryId: 'Beneficiario',
        userId: 'Usuario',
        percentage: 'Porcentaje',
        contributions: 'Contribuciones',
        publisher: 'Creador de contenido',
        type: 'Tipo',
        amount: 'Cantidad',
        items: 'Desglose',
        mgmUser: 'Usuario',
        beneficiary: 'Beneficiario',
      },
    },
    'beneficiary-earnings': {
      name: 'Ganancias(beneficiario)',
      fields: {
        total: 'Total',
        periodStart: 'Fecha comienzo',
        periodEnd: 'Fecha fin',
        beneficiarie: 'Beneficiario',
        publisher: 'Usuario',
        amount: 'Cantidad',
        contributions: 'Contribuciones',
        type: 'Tipo',
        publisher_donation: 'Donación de creador',
        user_donation: 'Donación de usuario',
      },
    },
  },
}
