import React from 'react'
import { Filter, Datagrid, TextField, TextInput, List, EditButton, BooleanField } from 'react-admin'
import { BulkActionsButtons } from '../../../molecules'

const PostFilter = props => (
  <Filter {...props}>
    <TextInput source='nickname' resettable autoComplete='off' />
  </Filter>
)

const list = props => (
  <List {...props} filters={<PostFilter />} exporter={false} bulkActionButtons={<BulkActionsButtons />}>
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='name' />
      <TextField source='nickname' />
      <TextField source='email' />
      <BooleanField source='publisher' textAlign='center' />
      <BooleanField source='enabled' textAlign='center' />
      <BooleanField source='banned' textAlign='center' />
      <EditButton />
    </Datagrid>
  </List>
)

export default list
